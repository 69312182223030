var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"sitesUpdate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"section-block withMaxHeight mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"MapIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Update site")])],1),(_vm.loading)?_c('b-spinner',{staticClass:"position-absolute",staticStyle:{"top":"50%","left":"48%"},attrs:{"variant":"primary","label":"Spinning"}}):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Name*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Name","autocomplete":"off","maxlength":"38"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,17517293)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Contact Name*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Contact Name","vid":"contact_name","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Contact Name","autocomplete":"off"},model:{value:(_vm.form.contact_name),callback:function ($$v) {_vm.$set(_vm.form, "contact_name", $$v)},expression:"form.contact_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1073034699)})],1)],1),_c('div',{staticClass:"neighbouring-form-container"},[(!_vm.isClient)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Company*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Company","vid":"company_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.options.companies.data,"placeholder":"Enter name","reduce":function (company_id) { return company_id.id; },"label":"name"},on:{"search":function($event){return _vm.filterCompanies($event)}},model:{value:(_vm.form.company_id),callback:function ($$v) {_vm.$set(_vm.form, "company_id", $$v)},expression:"form.company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3963346489)})],1):_vm._e(),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"NAS*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"NAS","vid":"nas_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.options.nas.data,"placeholder":"Select NAS","reduce":function (nas_id) { return nas_id.id; },"label":"name","disabled":_vm.isDisabledNasSelection},model:{value:(_vm.form.nas_id),callback:function ($$v) {_vm.$set(_vm.form, "nas_id", $$v)},expression:"form.nas_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3398490398)})],1)],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Email","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"email|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Email","autocomplete":"off"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1608355572)})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Phone*","label-for":"phone_number","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone_number","rules":"required|numeric|min:5|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{ref:"phoneNumber",staticClass:"form-control pl-0",attrs:{"id":"register-phone_number","name":"register-phone_number","preferred-countries":['GB'],"auto-default-country":false,"auto-format":false,"dropdown-options":{
                      showDialCodeInSelection: true,
                      showFlags: true,
                      showDialCodeInList: true
                    }},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4112413681)})],1),_c('b-form-group',{staticClass:"mb-50 mt-2"},[(_vm.form.company_id)?_c('b-form-checkbox',{attrs:{"id":"isSameAddress","name":"checkbox-1","value":1,"unchecked-value":0},model:{value:(_vm.isSameAddress),callback:function ($$v) {_vm.isSameAddress=$$v},expression:"isSameAddress"}},[_vm._v(" Same as Company Billing Address ")]):_vm._e()],1),(!_vm.isSameAddress)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address Line 1*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 1","vid":"address1","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Address Line 1","autocomplete":"off"},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2803520794)})],1):_vm._e(),(!_vm.isSameAddress)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Address Line 2","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Address Line 2","vid":"address2","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Address Line 2","autocomplete":"off"},model:{value:(_vm.form.address2),callback:function ($$v) {_vm.$set(_vm.form, "address2", $$v)},expression:"form.address2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4075250554)})],1):_vm._e(),(!_vm.isSameAddress)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Town / City*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Town / City ","vid":"city","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Town / City ","autocomplete":"off"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1494576985)})],1):_vm._e(),(!_vm.isSameAddress)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"State","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"State","vid":"state","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"State","autocomplete":"off"},model:{value:(_vm.form.state),callback:function ($$v) {_vm.$set(_vm.form, "state", $$v)},expression:"form.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3693920276)})],1):_vm._e(),(!_vm.isSameAddress)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Zip / Post Code*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Zip / Post Code*","vid":"postcode","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Zip / Post Code","autocomplete":"off"},model:{value:(_vm.form.postcode),callback:function ($$v) {_vm.$set(_vm.form, "postcode", $$v)},expression:"form.postcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1242653848)})],1):_vm._e(),(!_vm.isSameAddress)?_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Country*","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"country_id","rules":"max:255|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.options.countries.data,"reduce":function (country_id) { return country_id.id; },"placeholder":"Select country","label":"name"},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,962675708)})],1):_vm._e()],1):_vm._e()],1),_c('b-col',{staticClass:"text-right buttons-container__left",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","ok-variant":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.goBack.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }